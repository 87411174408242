import Axios from "axios";
require("dotenv").config();

export default class ScheduleService {
  static createSchedule = async (data) => {
    let response = null;
    await Axios({
      crossDomain: true,
      dataType: "json",
      method: "post",
      contentType: "application/json",
      url: `${process.env.REACT_APP_BASE_URL_API}v2/schedule_google_sheet/`,
      data: data,
    }).then((res) => {
      response = res.data;
    });
    return response;
  };

  static deleteSchedule = async (data) => {
    let response = null;
    await Axios.delete(
      `${process.env.REACT_APP_BASE_URL_API}v2/schedule_google_sheet/index.php?user=${data.user}&campaign=${data.campaign}`
    ).then((res) => {
      response = res.data;
    });
    return response;
  };

  static getSchedule = async (data) => {
    let response = null;
    await Axios.get(
      `${process.env.REACT_APP_BASE_URL_API}v2/schedule_google_sheet/?user=${data.user}&campaign=${data.campaign}`
    ).then((res) => {
      response = res.data;
    });
    return response;
  };
}
